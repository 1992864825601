import React, { useState, useEffect } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import "./App.css"

import Nav from "./components/shared/Nav"
import WhiteNav from "./components/shared/WhiteNav"
import Footer from "./components/shared/Footer"
import BlackFooter from "./components/shared/blackFooter"
import SplashScreen from "./components/splash/SplashScreen"
import RegisterScreen from "./components/registration/RegisterScreen"
import LoginScreen from "./components/login/LoginScreen"
import PasswordResetRequestScreen from "./components/password/PasswordResetRequestScreen"
import PasswordResetScreen from "./components/password/SetPasswordScreen"
import PrivacyPolicyScreen from "./components/legal/PrivacyPolicyScreen"
import EmailVerificationScreen from "./components/verifications/EmailVerificationScreen"
import PhoneVerificationScreen from "./components/verifications/PhoneVerificationScreen"
import ProfileScreen from "./components/profile/ProfileScreen"
import AccountSettingScreen from "./components/accountSettings/AccountSettingScreen"
import ResendVerificationScreen from "./components/verifications/ResendVerificationScreen"
import TermsScreen from "./components/legal/TermsScreen"
import GoalScreen from "./components/goal/GoalScreen"
import Subscribers from "./components/newsletter/Subscribers"
import SplashScreenV2 from "./components/splash/splashScreenv2"
import SplashScreenV2dot1 from "./components/splash/splashScreenv2-1"
import SplashScreen1c from "./components/splash/SplashScreen1c"
import SplashScreen2a from "./components/splash/SplashScreen2a"
import SplashScreenV4 from "./components/splash/SplashScreenv4"
import SplashScrenV5 from "./components/splash/SplashScreenv5"
import NavV5 from "./components/shared/NavV5"
import SplashScrenV6 from "./components/splash/SplashScreenv6"
import SplashScrenV7 from "./components/splash/SplashScreenv7"
import SplashScrenV7dot1 from "./components/splash/SplashScreenv7-1"
import SplashScrenV7dot2 from "./components/splash/SplashScreenv7-2"
import Footer7dot1 from "./components/shared/Footerv7-1"
import SilverBadge from "./components/splash/silver/SilverBadge"
import GoldBadge from "./components/splash/gold/GoldBadge"
import Page2 from "./components/splash/unsubscribe/page2"
import Unsubscribe from "../src/components/Unsubscribe"
import Resubscribe from "../src/components/Resubscribe"
import SplashScrenV7dot3 from "./components/splash/SplashScreenv7-3"
import SplashScrenV7dot4 from "./components/splash/SplashScreenv7-4"
import Bronzechallenge from "./components/splash/bronze/bronzechallenge"
import SplashScreenv8 from "./components/splash/SplashScreenv8"
import SilverBadgeNew from "./components/splash/silverNew/SilverBadge"
import WeeklyResubscribed from "./components/Weekly-Resubscribed"
import WeeklyUnsubscribe from "./components/Weekly-Unsubscribe"
import ChatNotificationResubscribe from "./components/Chat-Notification-Resubscribe"
import ChatNotificationUnsubscribe from "./components/Chat-Notification-Unsubscribe"
import friendResubscribed from "./components/friend-Resubscribe"
import friendUnsubscribe from "./components/friend-Unsubscribe"
import ReminderConfirmation from "./components/splash/ReminderConfirmation"
import SMSReminderConfirmation from "./components/splash/SMSReminderConfirmation"
import ReminderUnsubscribe from "./components/splash/ReminderUnsubscribe"
import SplashScrenV7dot5 from "./components/splash/SplashScreenv7-5"
import DeleteAccount from "./components/login/DeleteAccount"

declare global {
  interface Window {
    analytics: any
  }
}

function App() {
  let [path, setPath] = useState("")
  useEffect(() => {
    setPath(window.location.pathname)
  }, [])
  // useEffect(() => {
  //   if (
  //     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //       navigator.userAgent
  //     )
  //   ) {
  //     if (path) {
  //       if (path.split("/").length > 1) {
  //         let pathName = path.split("/")[1]
  //         console.log(pathName, "pathName")
  //         if (
  //           pathName !== "goldchallenge" &&
  //           pathName !== "silverchallenge" &&
  //           pathName !== "download" &&
  //           pathName !== "password-reset" &&
  //           pathName !== "set-password" &&
  //           pathName !== "privacy" &&
  //           pathName !== "unsubscribe" &&
  //           pathName !== "resubscribe" &&
  //           pathName !== "terms" &&
  //           pathName !== "invite" &&
  //           pathName !== "settings"
  //         ) {
  //           window.location.href = "/download"
  //         }
  //       }
  //     }
  //   } else {
  //     if (path) {
  //       if (path.split("/").length > 1) {
  //         let pathName = path.split("/")[1]
  //         console.log(pathName, "pathName")
  //         if (
  //           pathName === "creategoal" ||
  //           pathName === "chat" ||
  //           pathName === "trendinggoal" ||
  //           pathName === "invitefriend" ||
  //           pathName === "profile" ||
  //           pathName === "tribehub" ||
  //           pathName === "goldchallenge" ||
  //           pathName === "silverchallenge"
  //         ) {
  //           window.location.href = "/download"
  //         }
  //       }
  //       // if(path === ('creategoal' || ))
  //     }
  //   }
  // }, [path])

  // useEffect(() => {
  //   if (window.location.hostname.split(".")[0] === "web") {
  //     if (window.location.pathname.toLowerCase() != "/download") {
  //       window.location.href = "/download"
  //     }
  //   }
  // }, [])
  // console.log()

  return (
    <Router>
      <div>
        {path && path.toLocaleLowerCase() === "/download" ? (
          // <NavV5></NavV5>
          <WhiteNav />
        ) : path.toLocaleLowerCase() === "/" ||
          path.toLocaleLowerCase() === "/splashscrenv7dot3" ||
          path.toLocaleLowerCase() === "/splashscrenv7dot4" ||
          path.toLocaleLowerCase() === "/splashscrenv7dot5" ||
          path.toLocaleLowerCase() === "/splashscrenv7.1" ||
          path.toLocaleLowerCase() === "/splashscrenv7.2" ||
          path.toLocaleLowerCase() === "/splashscrenv8" ||
          path.toLocaleLowerCase() === "/reminder" ||
          path.includes("/invite/") ? (
          <NavV5></NavV5>
        ) : path.toLocaleLowerCase().includes("silverchallenge") ||
          path.toLocaleLowerCase().includes("goldchallenge") ||
          // path.toLocaleLowerCase().includes("silverbadgenew") ||
          path.toLocaleLowerCase().includes("bronzechallenge") ||
          // path.toLocaleLowerCase() === "/weeklyresubscribe" ||
          // path.toLocaleLowerCase() === "/weeklyunsubscribe" ||
          // path.toLocaleLowerCase() === "/chatnotificationresubscribe" ||
          // path.toLocaleLowerCase() === "/chatnotificationunsubscribe" ||
          // path.toLocaleLowerCase() === "/friendnotificationresubscribe" ||
          // path.toLocaleLowerCase() === "/friendnotificationunsubscribe" ||
          path.toLocaleLowerCase() === "/resubscribe" ||
          path.toLocaleLowerCase() === "/unsubscribe" ? (
          <></>
        ) : path &&
          (path.toLocaleLowerCase() === "/reminder_accept" ||
            path.toLocaleLowerCase() === "/smsreminderconfirmation" ||
            path.toLocaleLowerCase() === "/reminder_unsubscribe") ? null : (
          <Nav></Nav>
        )}
        <Switch>
          <Route
            path='/goal/:publicIdentifier'
            render={(props) => <GoalScreen {...props} />}
          />
          <Route path='/unsubscribe' component={Unsubscribe} />
          <Route path='/resubscribe' component={Resubscribe} />
          {/* <Route path='/weeklyresubscribe' component={WeeklyResubscribed} /> */}
          {/* <Route path='/weeklyunsubscribe' component={WeeklyUnsubscribe} /> */}
          {/* <Route path='/chatnotificationresubscribe' component={ChatNotificationResubscribe} /> */}
          {/* <Route path='/chatnotificationunsubscribe' component={ChatNotificationUnsubscribe} /> */}
          {/* <Route path='/friendnotificationresubscribe' component={friendResubscribed} />
          <Route path='/friendnotificationunsubscribe' component={friendUnsubscribe} /> */}
          <Route
            path='/resend-verification/:type'
            // component={ResendVerificationScreen}
            render={(props) => <ResendVerificationScreen {...props} />}
          />
          <Route
            path='/settings'
            // component={AccountSettingScreen}
            render={(props) => <AccountSettingScreen {...props} />}
          />
          <Route
            path='/profile/:code'
            // component={ProfileScreen}
            render={(props) => <ProfileScreen {...props} />}
          />
          <Route
            path='/phone-verification/'
            // component={PhoneVerificationScreen}
            render={(props) => <PhoneVerificationScreen {...props} />}
          />
          <Route
            path='/email-verification/:token'
            // component={EmailVerificationScreen}
            render={(props) => <EmailVerificationScreen {...props} />}
          />
          {/* <Route path='/report'>
            {() => {
              window.location.href = "https://forms.gle/odUiWoGJuyv8dtQK6"
            }}
          </Route> */}
          <Route
            path='/report'
            render={() => {
              window.location.href = "https://forms.gle/odUiWoGJuyv8dtQK6"
              return null
            }}
          />
          ;
          <Route
            path='/privacy'
            // component={PrivacyPolicyScreen}
            render={(props) => <PrivacyPolicyScreen {...props} />}
          />
          <Route
            path='/newsletter/subscriber-list'
            // component={Subscribers}
            render={(props) => <Subscribers {...props} />}
          />
          <Route
            path='/terms'
            // component={TermsScreen}
            render={(props) => <TermsScreen {...props} />}
          />
          <Route
            path={["/password-reset/:token", "/set-password/:token"]}
            // component={PasswordResetScreen}
            render={(props) => <PasswordResetScreen {...props} />}
          />
          <Route
            exact
            path='/password-reset'
            // component={PasswordResetRequestScreen}
            render={(props) => <PasswordResetRequestScreen {...props} />}
          />
          <Route
            path='/register/:code'
            // component={RegisterScreen}
            render={(props) => <RegisterScreen {...props} />}
          />
          <Route
            path='/login'
            // component={LoginScreen}
            render={(props) => <LoginScreen {...props} />}
          />
          {/* v1 
            import from splashscreen.tsx
          */}
          {/* <Route path={"/v1"} component={SplashScreen} /> */}
          {/* <Route path={"/V1c"} component={SplashScreen1c} /> */}
          {/* <Route path={"/V2a"} component={SplashScreen2a} /> */}
          {/* <Route path={["/invite/:code", "/v2.1"]} component={SplashScreenV2dot1} /> */}
          <Route
            exact
            path='/download'
            // component={SplashScreenV4}
            render={(props) => <SplashScreenV4 {...props} />}
          />{" "}
          {/*Yana version*/}
          {/* <Route exact path='/SplashScreenV5' component={SplashScrenV5} />  raza version */}
          {/* <Route path='/SplashScreenV6' component={SplashScrenV6} /> v6 8 frames version */}
          {/* <Route path='/COMING-SOON' component={SplashScrenV7} /> v7 comingsoon version */}
          {/* <Route path={/SplashScreenV2} component={SplashScreenV2} /> splashScreen v2 version */}
          <Route
            exact
            path={["/silverchallenge/:id", "/silverchallenge"]}
            // component={SilverBadge}
            render={(props) => <SilverBadge {...props} />}
          />{" "}
          {/* <Route
            exact
            path={["/SilverBadgeNew/:id", "/SilverBadgeNew"]}
            component={SilverBadgeNew}
          />{" "} */}
          <Route
            exact
            path={["/bronzechallenge/:id", "/bronzechallenge"]}
            // component={Bronzechallenge}
            render={(props) => <Bronzechallenge {...props} />}
          />{" "}
          <Route
            exact
            path={["/goldchallenge/:id", "/goldchallenge"]}
            // component={GoldBadge}
            render={(props) => <GoldBadge {...props} />}
          />{" "}
          {/* this real UI to be implmented  */}
          {/* <Route exact path='/unsubscribe' component={Page2} />{" "} */}
          {/* <Route
            exact
            path={"/SplashScrenV7.1"}
            component={SplashScrenV7dot1}
          />{" "}
          <Route exact path={"/SplashScrenV7"} component={SplashScrenV7} />{" "}
          {/* <SplashScrenV7dot2/> */}
          {/*splashScreen v7.1 version*/}
          <Route
            exact
            path={["/invite/:code", "/", "/reminder"]}
            // component={SplashScrenV7dot4}
            // component={SplashScrenV7dot5}
            render={(props) => <SplashScrenV7dot5 {...props} />}
          />{" "}
          {/* <Route
            exact
            path={[
              "/splashscrenv7dot5/invite/:code",
              "/splashscrenv7dot5",
              "/splashscrenv7dot5/reminder",
            ]}
            // component={SplashScrenV7dot4}
            component={SplashScrenV7dot5}
          />{" "} */}
          <Route
            exact
            path={"/reminder_accept"}
            component={ReminderConfirmation}
          />{" "}
          <Route
            exact
            path={"/reminder_unsubscribe"}
            component={ReminderUnsubscribe}
          />{" "}
          <Route exact path={"/delete_account"} component={DeleteAccount} />
          <Route
            exact
            path={"/SMSReminderConfirmation"}
            component={SMSReminderConfirmation}
          />{" "}
          <Route
            exact
            path={["/SplashScrenV7dot3"]}
            // component={SplashScrenV7dot3}
            render={(props) => <SplashScrenV7dot3 {...props} />}
          />{" "}
          <Route
            exact
            path={["/SplashScrenV8"]}
            // component={SplashScreenv8}
            render={(props) => <SplashScreenv8 {...props} />}
          />{" "}
          <Route
            exact
            path={["/SplashScrenV7dot4"]}
            // component={SplashScrenV7dot4}
            render={(props) => <SplashScrenV7dot4 {...props} />}
          />{" "}
          {/* current Deployed version */}
        </Switch>
        {path && path.toLocaleLowerCase() === "/download" ? (
          <BlackFooter />
        ) : path.toLocaleLowerCase() === "/splashscrenv7.1" ? (
          <Footer7dot1 />
        ) : path.toLocaleLowerCase().includes("silverchallenge") ||
          path.toLocaleLowerCase().includes("goldchallenge") ||
          // path.toLocaleLowerCase().includes("silverbadgenew") ||
          path.toLocaleLowerCase().includes("bronzechallenge") ||
          // path.toLocaleLowerCase() === "/weeklyresubscribe" ||
          // path.toLocaleLowerCase() === "/weeklyunsubscribe" ||
          // path.toLocaleLowerCase() === "/chatnotificationresubscribe" ||
          // path.toLocaleLowerCase() === "/chatnotificationunsubscribe" ||
          // path.toLocaleLowerCase() === "/friendnotificationresubscribe" ||
          // path.toLocaleLowerCase() === "/friendnotificationunsubscribe" ||
          path.toLocaleLowerCase() === "/resubscribe" ||
          path.toLocaleLowerCase() === "/unsubscribe" ? (
          <></>
        ) : path &&
          (path.toLocaleLowerCase() === "/reminder_accept" ||
            path.toLocaleLowerCase() === "/smsreminderconfirmation" ||
            path.toLocaleLowerCase() === "/reminder_unsubscribe") ? null : (
          <Footer></Footer>
        )}
        {path && path.toLocaleLowerCase() === "/reminder_accept" ? null : (
          <div className='acceptCookiePop' id='acceptCookiePop'>
            <div className='container'>
              <p>
                We use cookies and you consent to our collection of them for
                this website.
              </p>
              <div>
                <button id='consentBtn'>Ok</button>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.location.href = "/privacy"
                  }}
                >
                  Privacy Policy
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </Router>
  )
}

export default App
