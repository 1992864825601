import React, { useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { RouteComponentProps } from "react-router-dom"
import "./silverBadge.scss"
import handRightArrow from "./../../images/handIcon.svg"
import Star from "./../../images/Star.svg"
// import image from './../../assets/newWireframe/Group 2355.png'
interface PropsInterface
  extends RouteComponentProps<{
    id?: string
    code?: string
    uniqueSlider?: any
  }> {}
//   extends RouteComponentProps<> {}

/**
 * This is the landing page. Show links to app store and list of features.
 */
// interface RouteParams<any>

export default class SilverBadge extends React.Component<PropsInterface> {
  state = {
    tap: false,
  }
  constructor(props: any) {
    super(props)
  }
  componentDidMount() {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      window.location.href = "/download"
    }
  }

  render() {
    return (
      <div className='silverBadge'>
        <div id='mainDiv'>
          <div style={{ margin: "35px 15px" }}>
            <div className='HeadingLight'>
              <div>
                <p>7 Day Silver Badge Challenge</p>
                <p className='p2'>Win Cash & Cool Prizes</p>
              </div>
            </div>
            <div className='imgDiv '>
              <img
                style={{ height: "100%", width: "100%" }}
                src='https://goalmogul-v1.s3-us-west-2.amazonaws.com/emailTemplates/5_pages/silverBadgeIMG.png'
              />
            </div>
            {/* This should open when we hav the deeplink link Bronze challenge screen in App */}
            {/* <div className='text fs-6  text-Left youAre1Bronze'>
            You can participate in this Challenge only if you have earned the Bronze Badge first. If you have not earned the Bronze Badge yet, {" "}
              <a href=''
                style={{ color: "black", textDecoration: "underline" }}
              >tap here to see how you can earn the Bronze Badge first!</a>
            </div>
            <br /> */}
            {/* <div className="text">
          CONTINUE READING ONLY IF YOU HAVE EARNED YOUR BRONZE BADGE.<br/> THIS CHALLENGE IS ONLY AVAILABLE FOR BRONZE BADGE HOLDERS.
          </div>
          <br/>
          <div className="Username">Hey {firstName},</div> */}
            <div className='Username'>
              Welcome to the exclusive 7-Day Silver Badge Challenge!
            </div>
            <div style={{ margin: "5px auto" }}>
              <div className='text'>
                Now that you’ve shown you’re willing to share your goals with
                friends, you’ll want to get showered with encouraging comments,
                suggestions, and Likes from them almost instantly
              </div>
            </div>
            <div style={{ margin: "9px auto" }}>
              <div className='text'>
                Here’s how you can have all that AND unlock bonuses!
              </div>
            </div>
            <div className='Username'>
              Simply complete these (2) easy tasks in the next 7 days:{" "}
            </div>
            <div className='ShadowDiv'>
              <div
                className='TextDivWithCheckBox '
                style={{ color: "#FB0303" }}
              >
                <div className='text-color'>
                  <b>1</b>
                </div>
                <div className='text' style={{ color: "#FB0303" }}>
                  <b>Add 3 Goals</b>
                </div>
              </div>
              <div className='TextDivWithCheckBox' style={{ color: "#FB0303" }}>
                <div>
                  <b>2</b>
                </div>
                <div className='text' style={{ color: "#FB0303" }}>
                  <b>Invite 3 Friends who earn Green Badges*</b>
                  <br />
                  <i>
                    (*Each friend just has to fill in his profile and add 1
                    goal)
                  </i>
                </div>
              </div>
            </div>
            <div
              className='text'
              style={{ width: "98%", marginBottom: "18px" }}
            >
              Completing this Challenge will fast-track you to a more rewarding
              experience:
            </div>
            <div className='TextDivWithCheckBox'>
              <div>
                <img src={handRightArrow} />
              </div>
              <div className='text'>
                Find out exactly what your friends want to achieve in their
                lives & help them
              </div>
            </div>
            <div className='TextDivWithCheckBox'>
              <div>
                <img src={handRightArrow} />
              </div>
              <div className='text'>
                Multiply the number of productive relationships you have in your
                life
              </div>
            </div>
            <div className='TextDivWithCheckBox'>
              <div>
                <img src={handRightArrow} />
              </div>
              <div className='text'>
                Reveal to others you’re a unique person with many diverse goals
              </div>
            </div>
            <div className='TextDivWithCheckBox'>
              <div>
                <img src={handRightArrow} />
              </div>
              <div className='text'>
                Enhance your profile so other users are more likely to connect
                with you
              </div>
            </div>
            <div className='TextDivWithCheckBox'>
              <div>
                <img src={handRightArrow} />
              </div>
              <div className='text'>
                Improve your chances of sticking with your goals
              </div>
            </div>
            <div
              className='text'
              style={{ width: "98%", textAlign: "center", marginTop: "13px" }}
            >
              Also gain <b style={{ color: "black" }}>ACCESS</b> to these
              incredible features
            </div>
            <div className='ShadowDiv' style={{ padding: "13px 10px" }}>
              <div
                className='TextDivWithCheckBox'
                style={{ margin: "12px auto" }}
              >
                <div>🔥</div>
                <div className='text'>
                  <b style={{ color: "#FB0303" }}>UNLOCK #1:</b>{" "}
                  <b>GoMo Planner Premium:</b> Our full archive of 200+
                  life-changing questions that will help you stretch your mind,
                  reinvent yourself, and dream of endless possibilities for a
                  colossal future
                </div>
              </div>
              <div
                className='TextDivWithCheckBox'
                style={{ margin: "12px auto" }}
              >
                <div>🔥</div>
                <div className='text'>
                  <b style={{ color: "#FB0303" }}>UNLOCK #2:</b>{" "}
                  <b> Create Your Own Tribes:</b> Create your own Tribe,
                  establish authority, and start influencing your very own
                  audience!{" "}
                </div>
              </div>
              <div
                className='TextDivWithCheckBox'
                style={{ margin: "12px auto" }}
              >
                <div>🔥</div>
                <div className='text'>
                  <b style={{ color: "#FB0303" }}>UNLOCK #3:</b> <b>Qualify</b>{" "}
                  or ALL of GoalMogul’s future Challenges & chance to win
                  exclusive prizes!
                </div>
              </div>
            </div>
            <div
              className='text'
              style={{ width: "98%", textAlign: "center", color: "black" }}
            >
              If that weren’t enough, you’ll ALSO receive these astounding
              <b> BONUSES!</b>
            </div>
            <div className='ShadowDiv' style={{ padding: "13px 10px" }}>
              <div
                className='TextDivWithCheckBox'
                style={{ margin: "12px auto" }}
              >
                <div>🔥 🔥</div>
                <div className='text' style={{ width: "85%" }}>
                  <b style={{ color: "#FB0303" }}>BONUS #1:</b>{" "}
                  <b> ‘Crush Your Goals Like There’s No Tomorrow’ </b>
                  <br /> 163 page illustrated eBook by GoalMogul Founder Michael
                  Cheng - A crystal clear blueprint & complete arsenal of high
                  impact strategies to dominate your day. These closely guarded
                  secrets will get you gunning towards your dreams by leaps and
                  bounds!
                </div>
              </div>
              <div
                className='TextDivWithCheckBox'
                style={{ margin: "12px auto" }}
              >
                <div>🔥 🔥</div>
                <div className='text' style={{ width: "85%" }}>
                  <b style={{ color: "#FB0303" }}>BONUS #2 : </b>
                  Qualify for the 30-Day Gold Badge Challenge & chance to win a{" "}
                  <b style={{ color: "#FB0303" }}>$1,000 CASH</b> prize…)
                </div>
              </div>
            </div>
            <div
              className='text'
              style={{ width: "98%", textAlign: "center", color: "black" }}
            >
              AND for the <b>ONE</b> person who invites the
              <br /> <b style={{ color: "#43CA13" }}>MOST GREEN BADGE</b> users
              in the next 7 days:
            </div>
            <div className='ShadowDiv' style={{ padding: "13px 7px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div>🔥 🔥 🔥 </div>
                <div
                  className='text'
                  style={{ width: "54%", textAlign: "center" }}
                >
                  <b style={{ color: "#FB0303", fontSize: "16px" }}>
                    GRAND PRIZE: $100 CASH
                  </b>
                  <br />
                </div>
                <div>🔥 🔥 🔥 </div>
              </div>
              <div
                className='text midsmallText'
                style={{ textAlign: "center" }}
              >
                {" "}
                (paid via Paypal, see below for details***)
              </div>
            </div>
            <div
              className='text'
              style={{ margin: "15px auto", marginTop: "30px", color: "black" }}
            >
              But right now, you only have this ONE CHANCE to win these bonuses.
            </div>
            <div
              className='text'
              style={{ margin: "15px auto", color: "black" }}
            >
              This one-time offer is only available for the next 7 days.{" "}
            </div>
            {/* THIS BOX Commented in Silver Web Page As by Mike */}
            {/* <div className='ShadowDiv' style={{ padding: "10px 10px" }}>
              <div
                className='text'
                style={{
                  margin: "10px auto",
                  textAlign: "center",
                  color: "black",
                }}
              >
                <b>
                  Here's the FASTEST way to earn the Silver Badge right now:
                </b>
              </div>
              <div className='TextDivWithCheckBox'>
                <div>1)</div>
                <div className='text'>
                  Use the GoMo Planner answer 7+ questions
                </div>
              </div>
              <div className='TextDivWithCheckBox'>
                <div>2)</div>
                <div className='text'>Invite 3+ friends to view your goals</div>
              </div>
              <div className='TextDivWithCheckBox'>
                <div>3)</div>
                <div className='text'>
                  {" "}
                  Remind your friends to fill in their profiles and add their
                  1st goals
                </div>
              </div>
            </div> */}
            {/* <div className="text" style={{ margin: "20px auto", width: "85%", textAlign: "center", color: "black" }}>
            This Challenge expires on{" "}
            <b>exact date and time 7 days after Bronze badge was earned</b>
          </div> */}
            <div
              className='text'
              style={{
                textAlign: "center",
                marginBottom: "15px",
                color: "black",
              }}
            >
              <b> Don’t waste any time!</b>
              <br />
              Add 3 goals and invite your friends now!
            </div>
            <div className='buttonsMainDiv'>
              <button
                onClick={() =>
                  (window.location.href = "https://goalmogul.page.link/chat")
                }
                className='buttonTransparent'
              >
                Add 3 Goals using GoMo Planner
              </button>
              <button
                onClick={() =>
                  (window.location.href =
                    "https://goalmogul.page.link/invitefriend")
                }
                className='buttonBlue'
              >
                Share my goals with 3 friends
              </button>
            </div>
            <div
              className='text'
              style={
                this.state.tap
                  ? { textAlign: "center", marginBottom: "15px" }
                  : { textAlign: "center", marginBottom: "145px" }
              }
              onClick={() =>
                this.setState({
                  tap: true,
                })
              }
            >
              <b
                style={{
                  color: "#FB0303",
                  fontSize: "11px",
                }}
              >
                *** Tap here to read IMPORTANT Grand Prize Disclaimer! ***
              </b>
            </div>
            {this.state.tap ? (
              <>
                <div
                  className='text'
                  style={{ lineHeight: "24px", color: "black" }}
                >
                  If you are the winner of the Grand Prize, then YOU MUST HAVE A
                  PAYPAL ACCOUNT in order for us to send you the $100 cash
                  prize. At the end of the 7-Day Silver Badge Challenge, we will
                  announce the winner of the $100 Grand Prize.
                  <br />
                  <br />
                  <br />
                  If you are the Grand Prize winner, coach@goalmogul.com will
                  email you to request the following information for
                  verification purposes:
                  <br />
                  <br />
                </div>
                <div className='TextDivWithCheckBox'>
                  <div>
                    <img src={Star} />
                  </div>
                  <div className='text'>
                    <b>Full Name</b>
                  </div>
                </div>
                <div className='TextDivWithCheckBox'>
                  <div>
                    <img src={Star} />
                  </div>
                  <div className='text'>
                    <b>PayPal ID</b>
                  </div>
                </div>
                <div style={{ margin: "30px auto", marginBottom: "34%" }}>
                  <div className='footerText'>Please contact</div>
                  <div className='footerText'>
                    <a
                      href='mailto:support@goalmogul.com'
                      style={{ color: "#42c0f5" }}
                    >
                      {" "}
                      support@goalmogul.com
                    </a>
                  </div>
                  <div className='footerText'>if you have any questions.</div>
                </div>
                {/* <div className="text" style={{textAlign: "center",}}>
            Didn't mean to unsubscribe? Click here to resubscribe.
          </div> */}
              </>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}
